import 'focus-outline-manager';

import { Elm } from './elm/Main.elm'

Elm.Main.init();

// ensure buttons and anchors loose focus after click
document.addEventListener('click', event => {
  const { nodeName } = event.target;
  if (nodeName === 'A' || nodeName === 'BUTTON') {
    event.target.blur();
  }
}, { passive: true });
